import React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BasicDatePicker } from "../../../../includes/DatePicker";
import dayjs from "dayjs";

import {
  Grid,
  TextField,
  Typography,
  FormHelperText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Checkbox,
} from "@mui/material";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "../../../../assets/AccEditar.svg";

import SelectSucursales from "../../../Catalogos/Sucursales/Componentes/SelectSucursales";
import SelectHorario from "../SelectHorario";
import ModalSelectCliente from "../../../Catalogos/Clientes/Componentes/ModalSeelctCliente";
import { getClienteMostrador } from "../../Funciones/PuntoDeVente";

export default function TabsDatosEntrega({
  Values = {},
  handleInputChange = () => {},
  openModalValidations = () => {},
  expanded,
  handleChange = () => {},
  Fecha,
  setFecha = () => {},
  Errores = {},
  horarios,
  setHorarios = () => {},
  isEditar = false,
  idSucursal,
  checked,
  setChecked,
}) {

  let direccionDividida = []; 
  const [d, setD] = useState("");
  const [c, setC] = useState("");
  const [n, setN] = useState("");
  const [showModalCliente, setShowModalCliente] = useState(false); 
  const [cargando, setCargando] = useState(true); 
  const [datosClientes, setDatosClientes] = useState({
    id: Values.id_cliente,
    nombre: Values.nombre,
  }); 

 //  console.log(Values)

const handleChangeClienteMostrador = (event) => {
  setChecked(event.target.checked);  
  if(!event.target.checked)
  { 
    setDatosClientes({
      id_cliente: "0",
      nombre: "Selecciona un cliente",
    }); 
    handleInputChange({
      target: {
        name: "id_cliente",
        value: "0",
        nombre: "Selecciona un cliente",
      },
    }) 
  }
  
  
  setDatosClientes({ id: datosClientes.id, nombre: datosClientes.nombre });
};

  useEffect(() => {
    direccionDividida = Values.direccion.split("/");
    if (direccionDividida.length == 3) {
      setD(direccionDividida[0]);
      setC("Contacto: " + direccionDividida[1]);
      setN("Tel: " + direccionDividida[2]);
    } else {
      setD("");
      setC("");
      setN("");
    }
  }, [Values.direccion]);

 
const ChangeValues= (data)=>
{  
  handleInputChange({
    target: {
      name: "id_cliente",
      value: data.id,
      nombre: data.nombre,
    },
  });
  setDatosClientes ({
    id: data.id,
    nombre: data.nombre,
  }); 
}
 
  useEffect(() => {
    if (!isEditar) {
      if (checked) { 
        getClienteMostrador(idSucursal).then((resp) => { 
          setDatosClientes({
            id_cliente: resp.id_cliente,
            nombre: resp.nombre,
          }); 
          handleInputChange({
            target: {
              name: "id_cliente",
              value: resp.id_cliente,
              nombre: resp.nombre,
            },
          });
        }); 
      }
      else{   

        /*
        setDatosClientes({
          id_cliente: "0",
          nombre: "Selecciona un cliente",
        }); 
        handleInputChange({
          target: {
            name: "id_cliente",
            value: "0",
            nombre: "Selecciona un cliente",
          },
        }); 

        */
      }
    } 
  }, [checked]);

  useEffect(() => {
    setCargando(true)
     setTimeout(() => {
      setCargando(false)
     }, 1000);
  }, [Values.tipo_envio]);
 

  
 // alert(Values.id_sucursal)
  return (
    <>
      {showModalCliente && (
        <ModalSelectCliente
          setShowDialog={setShowModalCliente}
          setChecked={setChecked}
          setDatosReceptor={ChangeValues}
          titulo="Seleccionar un cliente"
        />
      )}
      <Col xs={12} className="p-3">
        <Card elevation={3}>
          <CardContent className="p-0">
            {!isEditar && (
              <>
                <div className="p-3 d-flex justify-content-between flex-wrap">
                  <Row>
                    <Col sm={12} md={12}>
                      <h6 className="mb-0 text-left font-AvenirBold">
                        Cliente
                      </h6>
                    </Col> 
                    <Typography>{datosClientes.nombre}</Typography>
                  </Row>
                  <div>
                    <Button
                      className="btn btn-Crexendo px-3 "
                      size="small"
                      variant="contained"
                      onClick={() => {
                        setShowModalCliente(true);
                      }}
                    >
                      Buscar cliente <SearchIcon className="ms-2" />
                    </Button>

                    <Col sm={12} md={12}>
                    <label>Cliente mostrador</label>
                      <Checkbox
                        checked={checked}
                        onChange={handleChangeClienteMostrador}
                        inputProps={{ "aria-label": "checkbox" }}
                      />
                     
                    </Col>
                  </div>
                </div>
                <hr
                  style={{
                    backgroundColor: "#DFDEE0",
                    height: "1px",
                    opacity: "1",
                  }}
                  className="m-0 p-0"
                />
              </>
            )}
           
          </CardContent>
        </Card>
      </Col>

      <Col xs={12} className="p-3 pt-1">
        <Accordion
          className="shadow"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary 
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            onClick={() => {
              handleInputChange({
                target: { name: "tipo_envio", value: 1 },
              });
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                height: "40px",
              }}
            >
              {Values.tipo_envio == 1 ? (
                <RadioButtonCheckedIcon />
              ) : (
                <RadioButtonUncheckedIcon />
              )} 
              <Typography marginLeft={2}>Entregar a domicilio</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{ marginBottom: "8px" }}
            >
              <Typography>Direccion de entrega</Typography>
              <div
                style={{
                  marginLeft: "20px",
                  cursor: "pointer",
                  direction: "initial",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  display: "flex",
                }}
                onClick={() => {
                  openModalValidations();
                }}
              >
                <img src={EditIcon} alt="" />
                <Typography style={{ marginLeft: "3px" }}>
                  Cambiar dirección
                </Typography>
              </div>
            </Grid> 
            <Typography fontWeight="bold">{d}</Typography>
            <Typography fontWeight="bold">{c}</Typography>
            <Typography fontWeight="bold"> {n}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary 
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            onClick={() => {
              handleInputChange({
                target: { name: "tipo_envio", value: 2 },
              });
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                height: "40px",
              }}
            >
              {Values.tipo_envio == 2 ? (
                <RadioButtonCheckedIcon />
              ) : (
                <RadioButtonUncheckedIcon />
              )} 
              <Typography marginLeft={2}>Recoger en Sucursal</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{ marginBottom: "8px" }}
            >
              <Typography marginBottom={2} marginRight={2}>Direccion de entrega</Typography>
{!cargando ? 

              <SelectSucursales
                W="60%"
                Values = {Values}
                Value={Values.id_sucursal}
                Error={Errores.id_sucursal}
                handlInputChange={handleInputChange}
                Name="id_sucursal"
                MostrarSucu={true}
                size="small"
              />
            :
            <TextField
            size={"small"}
            fullWidth
            disabled={true}
            value={"Cargando sucursales"}
            className={""}
          />


            
            }
            </Grid>
            <Typography fontWeight="bold">{Values.direccion}</Typography>
          </AccordionDetails>
        </Accordion>

        <FormHelperText id="component-error-text" error={Errores.tipo_envio}>
          {Errores.tipo_envio ? "Selecciona un tipo de envio" : ""}
        </FormHelperText>
        <FormHelperText id="component-error-text" error={Errores.direccion}>
          {Errores.direccion ? "Selecciona una dirección" : ""}
        </FormHelperText>
      </Col>

      <Col xs={12} className="p-3">
        <Row>
          <Col xs={6}>
            <BasicDatePicker
              fecha={Fecha}
              setFecha={setFecha}
              label={"Fecha de entrega"}
              size="small"
            />
            <FormHelperText error={Errores.fecha_entrega}>
              {Errores.fecha_entrega ? "Selecciona una fecha de entrega" : ""}
            </FormHelperText>
          </Col>
          <Col xs={6}>
            <SelectHorario
              size="small"
              //Name="va"
              Values = {Values}
              fecha={dayjs(Values.fecha_entrega).format("YYYY-MM-DD")}
              horario={horarios}
              setHorario={setHorarios}
              sucursal={Values.tipo_envio == 2 ? Values.id_sucursal : 0}
              Error={Errores.horarios_entrega}
              isEditar={isEditar}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={12} className="p-3">
        <TextField
          name="observaciones"
          placeholder="Informacion del punto de venta"
          helperText="Este dato es interno, el cliente no podrá verlo"
          label="Observaciones"
          value={Values.observaciones}
          onChange={handleInputChange}
          className="form-control"
          multiline
          rows={4}
        />
      </Col>
    </>
  );
}
