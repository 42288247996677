import { stringify } from "query-string";
import { postdData, postUrl, getData } from "../../../../Context/backend";
import {
  getDaysFromHours,
  obtenerMetodoPagoMasAlto,
} from "../../../../Lib/Funciones Generales/Generales";

export const getClienteMostrador = async (sucursalSelector) => {
  let queryParamsObj = {
    id_sucursal: sucursalSelector,
  };

  let data = [];
  const url = `ventas/getClienteMostrador?${stringify(queryParamsObj)}`;

  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        //console.log(data);
        let Values = {
          id_cliente: String(data.ID),
          nombre: String(data.NOMBRE) + " " + String(data.APELLIDO),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const guardar = async (
  Values,
  Errores,
  setErrores,
  productos = [],
  isRentable,
  descuento,
  precioEnvio,
  Incluye_IVA,
  ventaLibre = false,
  comisionBancaria = false
) => {
  let error = false;

  if (
    !Values.id_cliente ||
    Values.id_cliente == "" ||
    Values.id_cliente == "0" ||
    Values.id_cliente == "undefined"
  ) {
    Errores.id_cliente = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.fecha_entrega || Values.fecha_entrega == "") {
    Errores.fecha_entrega = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.tipo_envio || Values.tipo_envio == 0) {
    Errores.tipo_envio = true;
    setErrores(Errores);
    error = true;
  }

  switch (Values.tipo_envio) {
    case 1:
      if (!Values.id_direccion || Values.id_direccion <= 0) {
        Errores.direccion = true;
        setErrores(Errores);
        error = true;
      }
      break;
    case 2:
      if (
        !Values.id_sucursal ||
        Values.id_sucursal == "" ||
        Values.id_sucursal == "0"
      ) {
        Errores.direccion = true;
        setErrores(Errores);
        error = true;
      }
      break;
    default:
      Errores.tipo_envio = true;
      setErrores(Errores);
      error = true;
      break;
  }
  /*if (!Values.direccion || Values.direccion == "") {
    Errores.direccion = true;
    setErrores(Errores);
    error = true;
  }*/
  if (
    !Values.horario_entrega.inicio ||
    !Values.horario_entrega.fin ||
    Values.horario_entrega.inicio == "" ||
    Values.horario_entrega.fin == "" ||
    Values.horario_entrega.inicio == "00:00:00" ||
    Values.horario_entrega.fin == "00:00:00" ||
    Values.horario_entrega.inicio == "00:00" ||
    Values.horario_entrega.fin == "00:00"
  ) {
    Errores.horarios_entrega = true;
    setErrores(Errores);
    error = true;
  }

  /*if (isRentable) {
    if (!Values.nombre_contacto || Values.nombre_contacto == "") {
      Errores.nombre_contacto = true;
      setErrores(Errores);
      error = true;
    }
    if (!Values.telefono_contacto || Values.telefono_contacto == "") {
      Errores.telefono_contacto = true;
      setErrores(Errores);
      error = true;
    }
  }*/

  const newArrProds = productos.map((item) => {
    //console.log("DV", item);
    return {
      id: item.ID,
      precio: item.PRECIO,
      descontado: Number(item.DESCONTADO ?? 0).toFixed(2),
      cantidad: item.CANTIDAD,
      diaS_RENTA: String(
        item.RENTABLE ? getDaysFromHours(item.HORAS_RENTA) : 0
      ),
      COMPOSICION: item.COMPOSICION,
    };
  });
  Values.productos = newArrProds;

  // const newArrProdCompo = productoscompo.map((item) => {
  //   return {
  //     id: item.ID_PRODUCTO,
  //     precio: item.PRECIO,
  //     cantidad: item.CANTIDAD,
  //     DESCRIPCION_PRODUCTO: item.DESCRIPCION_PRODUCTO,
  //   };
  // });
  // console.log("Carrito Compo", newArrProdCompo);
  // Values.productoscompo = newArrProdCompo;

  Values.descuento = descuento.descuento ? descuento.descuento : 0;
  Values.tipo_descuento = descuento.tipo ? descuento.tipo : 0;
  Values.descuento_max = descuento.max ? descuento.max : 0;

  //console.log(Errores);
  if (productos.length <= 0 && !error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Inserta al menos un producto" });
    });
  }

  if (Values.id_zona <= 0 && Values.tipo_envio == 1 && !error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "La dirección seleccionada no cuenta con cobertura" });
    });
  }


  /*const vt = ValidateTimes(
    Values.horario_entrega.inicio,
    Values.horario_entrega.fin
  );
  if (vt.err && !error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: vt.msj });
    });
  }*/

  console.log(Values.metodos_pago);
  Values.metodos_pago.efectivo =
    Values.metodos_pago.efectivo == null ||
      Values.metodos_pago.efectivo == NaN ||
      Values.metodos_pago.efectivo == ""
      ? 0
      : Values.metodos_pago.efectivo;

  Values.metodos_pago.transferencia =
    Values.metodos_pago.transferencia == null ||
      Values.metodos_pago.transferencia == NaN ||
      Values.metodos_pago.transferencia == ""
      ? 0
      : Values.metodos_pago.transferencia;

  Values.metodos_pago.monedero =
    Values.metodos_pago.monedero == null ||
      Values.metodos_pago.monedero == NaN ||
      Values.metodos_pago.monedero == ""
      ? 0
      : Values.metodos_pago.monedero;

  Values.metodos_pago.tarjeta =
    Values.metodos_pago.tarjeta == null ||
      Values.metodos_pago.tarjeta == NaN ||
      Values.metodos_pago.tarjeta == ""
      ? 0
      : Values.metodos_pago.tarjeta;

  Values.metodos_pago.deposito =
    Values.metodos_pago.deposito == null ||
      Values.metodos_pago.deposito == NaN ||
      Values.metodos_pago.deposito == ""
      ? 0
      : Values.metodos_pago.deposito;

  Values.metodos_pago.pago_En_Tienda =
    !Values.metodos_pago.pago_En_Tienda ||
      Values.metodos_pago.pago_En_Tienda == null ||
      Values.metodos_pago.pago_En_Tienda === NaN ||
      Values.metodos_pago.pago_En_Tienda == ""
      ? 0
      : Values.metodos_pago.pago_En_Tienda;


  console.log(Values.metodos_pago.pago_En_Tienda);
  console.log(Values.metodos_pago);



  const metodoP = obtenerMetodoPagoMasAlto(Values.metodos_pago);
  //console.log("METODOS DE PAGO MAS ALTO: ", metodoP);
  console.log(metodoP);
  switch (metodoP) {
    case "transferencia":
      Values.metodo_pago = "TRANSFERENCIA";
      break;
    case "monedero":
      Values.metodo_pago = "MONEDERO";
      break;
    case "tarjeta":
      Values.metodo_pago = "TARJETA";
      break;
    case "efectivo":
      Values.metodo_pago = "EFECTIVO";
      break;
    case "deposito":
      Values.metodo_pago = "DEPOSITO";
      break;
    case "pago_En_Tienda":
      Values.metodo_pago = "PAGO EN TIENDA";
      break;
    default:
      Values.metodo_pago = "";
      break;
  }
  if (Values.pagado && !error) {
    const MP =
      Values.metodos_pago.tarjeta +
      Values.metodos_pago.transferencia +
      Values.metodos_pago.efectivo +
      Values.metodos_pago.monedero +
      Values.metodos_pago.deposito +
      Values.metodos_pago.pago_En_Tienda;


    let totalDescuento =
      descuento.tipo == 0
        ? 0
        : descuento.tipo == 1
          ? (Values.total * descuento.descuento) / 100 > descuento.max
            ? descuento.max
            : (Values.total * descuento.descuento) / 100
          : descuento.descuento;

    const Valor_IVA = 16;
    const IVA_FRACCION = Valor_IVA / 100 + 1;
    const RestaIVA = Values.total / IVA_FRACCION;
    const valorCalculado =
      Incluye_IVA === true ? Values.total : RestaIVA.toFixed(2);

    let total =
      parseFloat(valorCalculado - totalDescuento) +
      parseFloat(Values.costo_envio);
    Values.total = total;
    console.log(MP)
    console.log(total)
    if (MP < total) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Cubre el pago en su totalidad" });
      });
    }
  }

  console.log("ERRORES: ", Errores);
  Values.Incluye_iva = Incluye_IVA;
  Values.Venta_libre = ventaLibre;
  Values.Comision_bancaria = comisionBancaria;
  //console.log(Values);
  if (!error) {
    let data = [];

    Values.id = Number(Values.id);
    Values.costo_envio = Number(precioEnvio);
    let queryParamsObj = {
      existRentables: isRentable,
    };

    Values.id_cliente = String(Values.id_cliente);
    Values.id_sucursal = String(Values.id_sucursal);
    Values.id_descuento = descuento.id;
    Values.categoria_descuento = descuento.categoria;
    const body = Values;
    //console.log("Detalle Ventas", body);

    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? `ventas/Insertar?${stringify(queryParamsObj)}`
        : `ventas/Editar`;

    const res = await postdData(url, body);
    //console.log(descuento);
    //const res = { error: false, data: { codigo: "201", mensaje: "okey" } };
    //console.log(res);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;

        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const formatItemCarrito = (i) => {
  if (!i) return i;
  let item = {
    ...i,
    DESCUENTOS_CATEGORIA: i.DESCUENTOS_CATEGORIA ? JSON.parse(i.DESCUENTOS_CATEGORIA) : null,
    DESCUENTOS_CATEGORIA_PUNTO: i.DESCUENTOS_CATEGORIA_PUNTO ? JSON.parse(i.DESCUENTOS_CATEGORIA_PUNTO) : null,
  };
  item.PRECIO_ORIGINAL = item.PRECIO
  if (item.DESCUENTOS_CATEGORIA_PUNTO.TIPO_DESCUENTO != 0) {//PORCENTAJE
    item.PRECIO_SIN_DESCUENTO_PUBLICO = item.PRECIO_PUBLICO
    item.PRECIO_SIN_DESCUENTO = item.PRECIO
    item.PRECIO = Number(item.DESCUENTOS_CATEGORIA_PUNTO.PRECIO_DESCUENTO).toFixed(2);
    item.PRECIO_ORIGINAL = item.PRECIO
    item.PRECIO_PUBLICO = Number(item.DESCUENTOS_CATEGORIA.PRECIO_DESCUENTO).toFixed(2)
  }
  return item;
}

export const getUpdatedPrecio = (p, ventaLibre, comisionBancaria) => {
  let precio = p.PRECIO;
  let descontado = 0;
  if (!ventaLibre && !comisionBancaria) {
    precio = p.PRECIO_ORIGINAL
    descontado = (p.PRECIO_SIN_DESCUENTO ?? precio) - precio;
  }
  if (!ventaLibre && comisionBancaria) {
    precio = p.PRECIO_PUBLICO
    descontado = (p.PRECIO_SIN_DESCUENTO_PUBLICO ?? precio) - precio;
  }
  return ({
    ...p,
    PRECIO: precio,
    DESCONTADO: descontado.toFixed(2)
  })
}